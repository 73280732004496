import Providers from 'Providers';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import App from './App';
import GlobalCSS from './assets/styles/global';

function Main() {
  const isLightTheme = Boolean(false);
  return (
    <Providers isLightTheme={isLightTheme}>
      <BrowserRouter>
        <GlobalCSS isLightTheme={isLightTheme} />
        <App />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Providers>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);
