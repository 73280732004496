import { Box, Dialog, DialogActions, styled } from '@mui/material';

export const StyledDialog = styled(Dialog, {
  name: 'StyledDialog',
})(({ theme }) => ({
  '& .MuiDialogContent-root': {
    scrollbarColor: `${theme.palette.ochre.contrastText} ${theme.palette.ochre.contrastText}`,
  },

  '& .MuiPaper-root': {
    background: theme.palette.ochre.main,
    color: theme.palette.ochre.contrastText,
  },
}));

export const StyledWarningImage = styled(Box, {
  name: 'StyledWarningImage',
})(() => ({
  float: 'left',
  margin: '15px 30px 10px 6px',
  width: '100px',
}));

export const StyledDialogActions = styled(DialogActions, {
  name: 'StyledDialogActions',
})(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.ochre.contrastText}`,
}));

export default StyledDialog;
