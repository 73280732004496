import { darkTheme, lightTheme } from 'assets/styles/theme';
import { LessonsTabsProvider } from 'classes/classDashboard/lessonsTabsContext';
import { ClassTabsProvider } from 'classes/classDashboard/tabsContext';
import { Provider } from 'react-redux';
import store from 'utils/store';

import { ThemeProvider } from '@mui/material/styles';

const CombineProviders = ({ isLightTheme, children }) => (
  <Provider store={store}>
    <ThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
      <ClassTabsProvider>
        <LessonsTabsProvider>{children}</LessonsTabsProvider>
      </ClassTabsProvider>
    </ThemeProvider>
  </Provider>
);

export default CombineProviders;
