import WarningImage from 'assets/images/warning.png';

import { Button, DialogContent, Typography } from '@mui/material';

import { StyledDialog, StyledDialogActions, StyledWarningImage } from './styles';

const LeavingSiteWarningPopup = ({ open, handleClose, handleLink }) => (
  <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ elevation: 3 }}>
    <DialogContent>
      <Typography variant="h6" component="h2">
        <strong>WARNING: You’re Leaving Our Platform</strong>
      </Typography>
      <StyledWarningImage component="img" src={WarningImage} alt="warning" />
      <Typography sx={{ mb: 2 }}>
        You are about to navigate to an external site. Please be aware that this link will take you outside our
        platform, and we cannot be held responsible for the content, security, or experiences you may encounter on
        third-party websites.
      </Typography>
      <Typography>
        <strong>If you proceed, please keep the following in mind:</strong>
      </Typography>
      <ul>
        <li>
          <Typography>We cannot guarantee the accuracy, privacy, or security of external sites.</Typography>
        </li>
        <li>
          <Typography>Any actions you take on these sites are beyond our control and responsibility.</Typography>
        </li>
      </ul>
      <Typography>Would you like to continue?</Typography>
    </DialogContent>

    <StyledDialogActions>
      <Button size="large" variant="outlined" color="ochreDark" onClick={handleLink}>
        Continue
      </Button>
      <Button type="submit" size="large" variant="contained" color="ochreDark" onClick={handleClose}>
        Stay Here
      </Button>
    </StyledDialogActions>
  </StyledDialog>
);

export default LeavingSiteWarningPopup;
