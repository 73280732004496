import LeavingSiteWarningPopup from 'components/LeavingSiteWarningPopup';
import { useEffect, useMemo, useReducer, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Container,
  Fab,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { useGetSkillsQuery } from '../api/baseApiSlice';
import { GENZ_JOURNAL_DOWNLOAD_PDF_URL, LMS_BASE_URL } from '../config';
import { debounce } from '../utils/utilityFunctions';
import AddJournal from './components/AddJournal';
import JournalEntries from './components/JournalEntries';

const filtersInitialState = {
  page: 1,
  search: '',
  skill: '',
};

const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'page':
      return { ...state, page: action.payload };

    case 'search':
      return { ...state, page: 1, search: action.payload };

    case 'skill':
      return { ...state, page: 1, skill: action.payload };

    default:
      throw new Error(`Filters Reducer: Invalid action type (${action.type})`);
  }
};

const Journals = () => {
  const [filters, dispatch] = useReducer(filtersReducer, filtersInitialState);
  const { page, search, skill } = filters;

  const handleSearchChange = (event) => dispatch({ type: 'search', payload: event.target.value });
  const debouncedSearch = useMemo(() => debounce(handleSearchChange), []);
  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const [view, setView] = useState('grid');
  const [openAddJournal, setOpenAddJournal] = useState(false);
  const [openExternalLinkWarning, setOpenExternalLinkWarning] = useState(false);
  const [externalLink, setExternalLink] = useState(null);

  const { data: skills, isSuccess } = useGetSkillsQuery();

  const handleOpenAddJournal = () => setOpenAddJournal(true);
  const handleCloseAddJournal = () => setOpenAddJournal(false);
  const handleCloseExternalLink = () => setOpenExternalLinkWarning(false);

  const handleExternalLinkClick = () => {
    handleCloseExternalLink();
    if (externalLink?.target === '_blank') {
      window.open(externalLink.href);
    } else {
      window.location = externalLink.href;
    }
  };

  const handlePageChange = (_, newPage) => {
    window.scrollTo(0, 0);
    dispatch({ type: 'page', payload: newPage });
  };

  const handleViewChange = (_, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      const link = event.target.closest('a');
      if (
        link &&
        link.href.startsWith('http') &&
        !(link.href.includes(window.location.hostname) || link.href.includes(LMS_BASE_URL))
      ) {
        event.preventDefault();
        setOpenExternalLinkWarning(true);
        setExternalLink(link);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return (
    <Container maxWidth="md">
      <Typography component="h1" variant="h5">
        My Journal
      </Typography>
      <Grid container mt={4} sx={{ alignItems: 'center' }} spacing={1}>
        <Grid item xs={12} md={5}>
          <InputLabel htmlFor="search-journal" className="sr-only">
            Search Journals
          </InputLabel>
          <TextField
            id="search-journal"
            placeholder="Search..."
            size="small"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={debouncedSearch}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ overflow: 'visible' }}>
            <TextField
              select
              id="select-skill-filter"
              label="Skill"
              size="small"
              fullWidth
              value={skill}
              onChange={(e) => dispatch({ type: 'skill', payload: e.target.value })}
            >
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              {isSuccess &&
                skills.map((currentSkill) => (
                  <MenuItem value={currentSkill.id} key={currentSkill.id}>
                    {currentSkill.name}
                  </MenuItem>
                ))}
            </TextField>
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: 2 }}>
          <ToggleButtonGroup value={view} exclusive onChange={handleViewChange} aria-label="journal view" size="small">
            <ToggleButton value="grid" aria-label="grid view" sx={{ py: 1, px: 1.5 }}>
              <GridViewIcon />
            </ToggleButton>

            <ToggleButton value="list" aria-label="list view" sx={{ py: 1, px: 1.5 }}>
              <ListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Button variant="contained" download href={`${GENZ_JOURNAL_DOWNLOAD_PDF_URL}`} hidden>
            Export
          </Button>
        </Grid>
      </Grid>

      <JournalEntries view={view} filters={{ skillId: skill, search, page }} handlePageChange={handlePageChange} />

      <Fab
        variant="extended"
        color="primary"
        aria-label="add journal"
        sx={{ position: 'fixed', right: 32, bottom: 32 }}
        onClick={handleOpenAddJournal}
      >
        <AddIcon sx={{ mr: 1 }} />
        Add
      </Fab>

      <AddJournal open={openAddJournal} handleClose={handleCloseAddJournal} skills={skills} />
      <LeavingSiteWarningPopup
        open={openExternalLinkWarning}
        handleLink={handleExternalLinkClick}
        handleClose={handleCloseExternalLink}
      />
    </Container>
  );
};

export default Journals;
