import { createContext, useContext, useReducer } from 'react';

const ClassTabsContext = createContext();

export const LESSON_TABS_ACTIONS = {
  CHANGE_LESSONS: 'CHANGE_LESSONS',
  RESET_LESSONS_TABS: 'RESET_LESSONS_TABS',
};

const tabsInitialState = 0;

const tabsReducer = (state, action) => {
  switch (action?.type) {
    case LESSON_TABS_ACTIONS.CHANGE_LESSONS:
      return action.payload;

    case LESSON_TABS_ACTIONS.RESET_LESSONS_TABS:
      return tabsInitialState;

    default:
      throw new Error(`Tabs Reducer: Invalid action type (${action?.type})`);
  }
};
export const LessonsTabsProvider = ({ children }) => {
  const [activeLessonTab, dispatch] = useReducer(tabsReducer, tabsInitialState);

  return <ClassTabsContext.Provider value={{ activeLessonTab, dispatch }}>{children}</ClassTabsContext.Provider>;
};

export const useLessonsTabs = () => {
  const context = useContext(ClassTabsContext);
  if (!context) {
    throw new Error('useLessonsTabs must be used within a LessonsTabsProvider');
  }
  return context;
};
