import { useGetClassQuery } from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useParams } from 'react-router-dom';

import { MenuBook as MenuBookIcon } from '@mui/icons-material';
import { Box, Grid, Paper, Stack, Tab, Tabs, Typography, alpha, useMediaQuery, useTheme } from '@mui/material';

import { LESSON_TABS_ACTIONS, useLessonsTabs } from '../../lessonsTabsContext';
import { ClassSummaryBorderTab } from '../../styles';
import ClassSummarySkeleton from './ClassSummarySkeleton';
import LessonCard from './LessonCard';
import StudentList from './StudentList';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`unit-tabpanel-${index}`}
      aria-labelledby={`unit-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const ClassSummary = () => {
  const { activeLessonTab, dispatch } = useLessonsTabs();
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { classId } = useParams();
  const { data: classData, isLoading, isFetching, isSuccess } = useGetClassQuery({ classId });

  const handleTabChange = (event, newValue) => {
    dispatch({ type: LESSON_TABS_ACTIONS.CHANGE_LESSONS, payload: newValue });
  };

  return (
    <>
      {isLoading && <ClassSummarySkeleton />}
      <Paper elevation={4} sx={{ position: 'relative' }}>
        {!isLoading && isFetching && <BackdropLinearProgress />}

        {isSuccess && (
          <>
            <Box borderBottom={1} borderColor="divider" sx={{ p: 2 }}>
              <Typography component="h1" variant="h6">
                Units &amp; Lessons
              </Typography>
            </Box>

            {classData.results.length ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2.5}
                  sx={{
                    py: 2,
                    borderRight: {
                      xs: 0,
                      sm: `1px solid ${theme.palette.divider}`,
                    },
                    borderBottom: {
                      xs: `1px solid ${theme.palette.divider}`,
                      sm: 0,
                    },
                  }}
                >
                  <Tabs
                    orientation={isScreenLtSm ? 'horizontal' : 'vertical'}
                    variant="scrollable"
                    scrollButtons="auto"
                    value={activeLessonTab}
                    onChange={handleTabChange}
                    aria-label="unit selection tab"
                    selectionFollowsFocus
                  >
                    {classData.results.map((unit, i) => (
                      <Tab
                        label={
                          <Stack direction="row" alignItems="center" gap={1}>
                            <MenuBookIcon fontSize="small" />
                            <Typography variant="body2" textAlign="left">
                              {unit.displayName}
                            </Typography>
                          </Stack>
                        }
                        key={unit.id}
                        {...{
                          id: `unit-tab-${i}`,
                          'aria-controls': `unit-tabpanel-${i}`,
                        }}
                        sx={{
                          alignItems: 'flex-start',
                          textTransform: 'none',
                          '&.Mui-selected': {
                            backgroundColor: alpha(theme.palette.primary.dark, 0.16),
                            borderRadius: 0,
                          },
                        }}
                      />
                    ))}

                    <ClassSummaryBorderTab
                      label={<></>}
                      disabled
                      {...{
                        id: `unit-tab-${classData.results.length}`,
                        'aria-controls': `unit-tabpanel-${classData.results.length}`,
                      }}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={12} sm={8} md={9.5}>
                  {classData.results.map((unit, i) => (
                    <TabPanel value={activeLessonTab} index={i} key={unit.id}>
                      <Grid container p={2} spacing={2}>
                        {unit.classLessons.length ? (
                          unit.classLessons.map((lesson) => (
                            <Grid item key={lesson.id} xs={12} xss={6} md={3}>
                              <LessonCard lesson={lesson} />
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">No Lessons Available!</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </TabPanel>
                  ))}

                  <TabPanel value={activeLessonTab} index={classData.results.length}>
                    Coming Soon!
                  </TabPanel>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h4" py={2} textAlign="center">
                No Units Found!
              </Typography>
            )}
          </>
        )}
      </Paper>

      <Box mt={4}>
        <StudentList />
      </Box>
    </>
  );
};

export default ClassSummary;
