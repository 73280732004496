import {
  useAddClassMutation,
  useGetClassQuery,
  useGetPrimaryStudentsQuery,
  useRemoveClassStudentsMutation,
} from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { StyledDataGrid } from '../../styles';
import StudentCell from './StudentCell';

const AddRemoveStudents = ({ open, closeModal, addStudents }) => {
  const { classId } = useParams();
  const { data: classData } = useGetClassQuery({ classId });
  const { data: studentList, isLoading } = useGetPrimaryStudentsQuery({ addStudents, classId });
  const [submitStudents, { isSuccess: studentsAdded, isLoading: studentsAdding }] = useAddClassMutation();
  const [removeStudents, { isSuccess: studentsRemoved, isLoading: studentsRemoving }] =
    useRemoveClassStudentsMutation();

  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const [students, setSudents] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);

  const handleCloseModal = () => {
    closeModal();
  };

  const NoDataOverlay = () => (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
      <Typography variant="h6">No Results Found!</Typography>
    </Stack>
  );

  const CircularBackdropProgress = () => (
    <Backdrop open={!!true} sx={{ position: 'relative', zIndex: 5, height: '100%' }}>
      <CircularProgress />
    </Backdrop>
  );

  let columns = [
    {
      field: 'student',
      headerName: 'Name',
      width: 300,
      renderCell: (params) => <StudentCell student={params.value} />,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
  ];

  if (addStudents) {
    columns = [...columns, { field: 'currentClass', headerName: 'Current Class', width: 200 }];
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (addStudents) {
      await submitStudents({ classId, studentIds: students })
        .unwrap()
        .then(() => {
          setSudents([]);
        });
    } else {
      await removeStudents({ classId, studentIds: students })
        .unwrap()
        .then(() => {
          setSudents([]);
        });
    }
  };

  useEffect(() => {
    setRows([]);
    if (!isLoading) {
      const recs = studentList.map((student) => ({
        id: student.id,
        email: student.email,
        student: {
          name: student.username,
          profilePic: student.profilePic,
        },
        currentClass: student.alreadyEnrolledClasses.length > 0 ? student.alreadyEnrolledClasses : '-',
      }));
      setRows(recs);
      setSelectionModel(students);
    }
  }, [isLoading, studentList, students]);

  return (
    <Dialog
      aria-labelledby="Add Students"
      aria-describedby="A modal window for Adding Students from a class"
      open={open}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
      fullScreen={isScreenLtSm}
      PaperProps={{
        elevation: 4,
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      {studentsAdded || studentsRemoved ? (
        <Stack justifyContent="center" alignItems="center" py={{ xs: 8, sm: 15 }}>
          {closeModal ? (
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <CheckCircleIcon color="primary" sx={{ width: 120, height: 120 }} />
          <Typography mb={2} variant="body2">
            Student(s) {addStudents ? 'Added' : 'Removed'} Successfully!
          </Typography>
          <Button onClick={handleCloseModal} variant="outlined" size={buttonSize()}>
            Close
          </Button>
        </Stack>
      ) : (
        <>
          <Box pt={{ xs: 2, sm: 4 }} px={2} sx={{ position: 'relative' }}>
            <Typography variant="h6" component="h2" sx={{ mb: 1, textAlign: 'center' }}>
              {classData?.name} - {classData?.programName}
            </Typography>
            <Typography variant="body2" sx={{ mb: 3, textAlign: 'center' }}>
              {addStudents
                ? 'Only students who have logged in before will appear in this list.'
                : 'Select students to remove'}
            </Typography>
            {addStudents && (
              <Alert variant="outlined" severity="info" icon={<InfoIcon fontSize="inherit" />}>
                <Typography variant="body2" component="span" color="text.primary">
                  Students can add themselves to this class with the following code: {classData?.groupId}
                </Typography>
              </Alert>
            )}
          </Box>
          <Box mt={{ xs: 1, sm: 3 }} mb={3}>
            <Box
              sx={{
                width: '100%',
                height: 400,
                position: 'relative',
                overflow: 'auto',
              }}
              borderBottom={1}
              borderColor="primary.dark"
            >
              {(studentsAdding || studentsRemoving) && <BackdropLinearProgress />}
              <StyledDataGrid
                rows={rows}
                columns={columns}
                components={{
                  LoadingOverlay: CircularBackdropProgress,
                  NoRowsOverlay: NoDataOverlay,
                }}
                loading={isLoading}
                autoHeight
                disableSelectionOnClick
                checkboxSelection
                hideFooter
                disableColumnMenu
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                selectionModel={selectionModel}
                onSelectionModelChange={(ids) => {
                  setSudents(ids);
                  setSelectionModel(ids);
                }}
              />
            </Box>
          </Box>
          <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 4 }} pb={3}>
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={students.length === 0 || studentsAdding || studentsRemoving}
                size={buttonSize()}
              >
                {addStudents ? 'Confirm' : 'Remove'}
              </Button>
            </Stack>
            <Button onClick={closeModal} variant="outlined" size={buttonSize()}>
              Cancel
            </Button>
          </Stack>
        </>
      )}
    </Dialog>
  );
};

export default AddRemoveStudents;
